<template>
  <main class="connectionsView">
    <b-breadcrumb :items="breadcrumb" />
    <div v-if="viewShow" class="container" :class="{ 'mx-auto': !sidebarShow }">
      <SectionUserInfo v-if="forClient" />
      <section class="section connectionsView-section">
        <div class="section-title connectionsView-title">
          Reporting Connections Details
        </div>
        <div class="connectionsView-btns">
          <router-link
            class="btn btn-primary"
            :to="`/connections/${$route.params.id}/edit`"
            >Edit connection</router-link
          >
          <button
            class="btn"
            :class="{
              'btn-primary': connection.status === 'ACTIVE',
              'btn-danger': connection.status === 'INACTIVE'
            }"
            @click.prevent="
              connectionStatusHandler(connection.id, connection.status)
            "
          >
            Make {{ connection.status === 'ACTIVE' ? 'Inactive' : 'Active' }}
          </button>
        </div>
      </section>
      <ConnectionsViewInfo :connection="connection" :publicPath="publicPath" />
      <ConnectionsViewRecords />
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SectionUserInfo from '../components/SectionUserInfo.vue'
import ConnectionsViewRecords from '../components/ConnectionsViewRecords.vue'
import ConnectionsViewInfo from '../components/ConnectionsViewInfo.vue'

export default {
  name: 'ConnectionsView',
  components: { SectionUserInfo, ConnectionsViewRecords, ConnectionsViewInfo },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      breadcrumb: [
        {
          text: 'Reporting Connections',
          to: '/connections'
        },
        {
          text: 'Reporting Connections Details',
          active: true
        }
      ],
      viewShow: false
    }
  },
  async mounted() {
    let id = this.$route.params.id
    let status = await this['connection/pageViewCreated']({ id })
    if (status === 'success') {
      this.viewShow = true
    } else {
      console.log('connectionView created: ' + status)
      this.$router.push('/pages/page403')
    }
  },
  computed: {
    ...mapState({
      sidebarShow: state => state.sidebarShow,
      user: state => state.user.self,
      connection: state => state.connection.view
    }),
    forClient() {
      return this.user.role === 'CLIENT'
    }
  },
  methods: {
    ...mapActions([
      'connection/pageViewCreated',
      'connection/pageViewStatusHandler'
    ]),
    async connectionStatusHandler(id, status) {
      await this['connection/pageViewStatusHandler']({
        id,
        status
      })
      console.log('connection status change: ' + status)
    }
  }
}
</script>
