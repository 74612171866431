<template
  ><div class="pagination">
    <ul class="pagination-list" v-if="paginationShow">
      <li class="page-item" v-if="totalPages > 3">
        <a
          href="#"
          class="page-link"
          :class="{ disabled: !hasFirst }"
          @click.prevent="pageClickFirst()"
        >
          <span>«</span>
        </a>
      </li>
      <li class="page-item" v-if="totalPages >= 2">
        <a
          href="#"
          class="page-link"
          :class="{ disabled: !hasPrev }"
          @click.prevent="pageClickPrev()"
        >
          <span>‹</span>
        </a>
      </li>
      <li class="page-item disabled" v-if="dotPrev">
        <span class="page-link">…</span>
      </li>
      <li
        class="page-item"
        :class="{ active: item == current }"
        v-for="item of pages"
        :key="item"
      >
        <a href="#" class="page-link" @click.prevent="pageClick(item)">{{
          item
        }}</a>
      </li>
      <li class="page-item disabled" v-if="dotNext">
        <span class="page-link">…</span>
      </li>
      <li class="page-item" v-if="totalPages >= 2">
        <a
          href="#"
          class="page-link"
          :class="{ disabled: !hasNext }"
          @click.prevent="pageClickNext()"
        >
          <span>›</span>
        </a>
      </li>
      <li class="page-item" v-if="totalPages > 3">
        <a
          href="#"
          class="page-link"
          :class="{ disabled: !hasLast }"
          @click.prevent="pageClickLast()"
        >
          <span>»</span>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    current: { type: Number, require: true },
    total: { type: Number, require: true },
    perPage: { type: Number, require: true },
    pageRange: { type: Number, default: 2 }
  },
  data() {
    return {}
  },
  computed: {
    paginationShow() {
      return this.perPage < this.total
    },
    pages() {
      let pages = []
      for (var i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }
      return pages
    },
    rangeStart() {
      let start = this.current - this.pageRange
      return start > 0 ? start : 1
    },
    rangeEnd() {
      var end = this.current + this.pageRange
      return end < this.totalPages ? end : this.totalPages
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage)
    },
    nextPage() {
      return this.current + 1
    },
    prevPage() {
      return this.current - 1
    },
    // has
    hasPrev() {
      return this.current > 1
    },
    hasNext() {
      return this.current < this.totalPages
    },
    hasFirst() {
      return this.current !== 1
    },
    hasLast() {
      return this.current < this.totalPages
    },
    dotPrev() {
      return this.rangeStart > 1
    },
    dotNext() {
      return this.rangeEnd < this.totalPages
    }
  },
  methods: {
    pageClick(item) {
      this.$emit('currentPageUpdate', item)
    },
    pageClickPrev() {
      let page = this.current > 1 ? this.current - 1 : this.current
      this.$emit('currentPageUpdate', page)
    },
    pageClickNext() {
      let page = this.current < this.total ? this.current + 1 : this.current
      this.$emit('currentPageUpdate', page)
    },
    pageClickFirst() {
      this.$emit('currentPageUpdate', 1)
    },
    pageClickLast() {
      this.$emit('currentPageUpdate', this.totalPages)
    }
  },
  watch: {
    perPage() {
      console.log(123)
      if (this.current > this.pages.length) {
        this.pageClickFirst()
      }
    }
  }
}
</script>
