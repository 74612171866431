<template>
  <section class="section connectionsView-section records">
    <div class="section-title connectionsView-title">History</div>
    <div class="section-table record-table">
      <div class="section-table-content">
        <table class="table">
          <thead>
            <tr>
              <th class="record-td-data" scope="col">Report Date</th>
              <th class="record-td-status" scope="col">Status</th>
              <th class="record-td-update" scope="col">
                Update Date
              </th>
              <th class="record-td-upload" scope="col">Upload Date</th>
              <th class="record-td-verification" scope="col">
                Verification Date
              </th>
              <th class="record-td-retry" scope="col"></th>
              <th class="record-td-download" scope="col">
                Report Download
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item of renderRecord"
              :key="item.id"
              :class="{
                'record-status-failed': recordStatusIfFailed(item.status)
              }"
            >
              <td class="record-td-data" scope="row">
                {{ item.reportDate }}
              </td>
              <td class="record-td-status">
                {{ recordStatusToText(item.status) }}
              </td>
              <td class="record-td-update">
                {{ dateFormat(item.updatedAt) }}
              </td>
              <td class="record-td-upload">
                {{ dateFormat(item.uploadedAt) }}
              </td>
              <td class="record-td-verification">
                {{ dateFormat(item.verifiedAt) }}
              </td>
              <td class="record-td-retry">
                <button
                  v-if="recordStatusIfFailed(item.status)"
                  class="btn btn-danger record-retryBtn"
                  @click.prevent="recordRetry(item.id)"
                >
                  <span class="material-icons">autorenew</span>
                  Retry
                </button>
              </td>
              <td class="record-td-download">
                <div class="record-download" v-if="item.reports[0]">
                  <div
                    class="record-download-item"
                    v-for="report of item.reports"
                    :key="report.id"
                  >
                    <div class="download-content">
                      <a
                        class="btn btn-link"
                        targrt="_blank"
                        :href="
                          `https://bonder.fivestones.net/v1/reports/${report.id}/download`
                        "
                      >
                        <div class="record-download-icon">
                          <img :src="`${publicPath}img/icon-csv2.svg`" alt="" />
                        </div>
                        <div class="record-download-text">
                          {{
                            report.type === 'TRANSFORMED_REPORT'
                              ? 'Facebook Report'
                              : 'SA360 Report'
                          }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        :current="currentPage"
        :total="recordsLength"
        :perPage="perPage"
        @currentPageUpdate="currentPageUpdateHandler"
      ></Pagination>
    </div>
  </section>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Pagination from './Pagination.vue'

export default {
  name: 'ConnectionsViewRecords',
  components: { Pagination },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      //Pagination
      perPage: 10,
      currentPage: 1
    }
  },
  computed: {
    ...mapState({
      taskRecords: state => state.connection.taskRecords,
      connection: state => state.connection.view
    }),
    //Pagination
    renderRecord() {
      let start = (this.currentPage - 1) * this.perPage
      let end = start + this.perPage
      return this.taskRecords.slice(start, end)
    },
    recordsLength() {
      return this.taskRecords ? this.taskRecords.length : 0
    }
  },
  methods: {
    ...mapActions(['connection/pageViewRecordRetryHandler']),
    dateFormat(date) {
      if (date == null) return 'null'
      return new Date(date).toLocaleDateString()
    },
    recordStatusIfFailed(status) {
      return (
        status === 'REPORT_REQUEST_FAILED' ||
        status === 'REPORT_UPLOAD_FAILED' ||
        status === 'VERIFICATION_FAILED'
      )
    },
    recordStatusToText(status) {
      let toArr = status.split('_')
      return toArr.map(x => this.titleCase(x)).join(' ')
    },
    titleCase(str) {
      return str.slice(0, 1) + str.slice(1).toLowerCase()
    },
    //Pagination
    currentPageUpdateHandler(currentPage) {
      this.currentPage = currentPage
    },
    async recordRetry(recordId) {
      const id = this.connection.id
      let status = await this['connection/pageViewRecordRetryHandler']({
        id,
        recordId
      })
      console.log('connection recordRetry: ' + status)
    }
  }
}
</script>
