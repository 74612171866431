<template>
  <section class="section connectionsView-section details">
    <div class="details-row row">
      <div class="details-col col-12 col-xl-6 details-account">
        <div class="details-account-content">
          <div class="row details-account-row">
            <div class="col details-account-col">
              <div class="details-account-icon">
                <img :src="`${publicPath}img/facebook.svg`" alt="" />
              </div>
              <div class="details-account-infoName">
                {{ connection.facebookAdAccount.accountName }}
              </div>
              <div class="details-account-infoId">
                ID: {{ connection.facebookAdAccount.accountId }}
              </div>
            </div>
            <div class="col details-account-arrow">
              <span class="material-icons">
                arrow_forward_ios
              </span>
            </div>
            <div class="col details-account-col">
              <div class="details-account-icon">
                <img :src="`${publicPath}img/sa360.svg`" alt="" />
              </div>
              <div class="details-account-infoName">
                {{ connection.sa360Account.accountName }}
              </div>
              <div class="details-account-infoId">
                ID: {{ connection.sa360Account.accountId }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="details-col col-md-6 col-xl-3 details-card">
        <div class="details-card-content">
          <div class="details-card-title">
            SA360 sFTP details
          </div>
          <div class="details-card-group">
            <div class="details-card-label">User Name</div>
            <div class="details-card-text">
              {{ connection.sa360Account.sftpUsername }}
            </div>
          </div>
          <div class="details-card-group">
            <div class="details-card-label">Password</div>
            <div class="details-card-text">
              ********
            </div>
          </div>
        </div>
      </div>
      <div class="details-col col-md-6 col-xl-3 details-card">
        <div class="details-card-content">
          <div class="details-card-title">
            Schedule
          </div>
          <div class="details-card-group">
            <div class="details-card-label">Time</div>
            <div class="details-card-text">
              {{ formatTime(connection.time) }}
            </div>
          </div>
          <div class="details-card-group">
            <div class="details-card-label">Time Zone</div>
            <div class="details-card-text">{{ connection.timezone }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'ConnectionsViewInfo',
  props: ['publicPath', 'connection'],
  methods: {
    formatTime(number) {
      return String(number).padStart(2, '0') + ':00'
    }
  }
}
</script>
