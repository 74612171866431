<template>
  <section class="section section-userInfo">
    <div class="section-userInfo-company" v-if="user">
      Signed in as
      <span class="font-weight-bold">{{ user.subscription.companyName }}</span>
    </div>
    <div class="section-userInfo-active" v-if="connectionsShow">
      Active connections:
      <span class="font-weight-bold">{{ connectionsActive }}</span>
    </div>
    <slot name="someTips"></slot>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SectionUserInfo',
  props: { connectionsShow: { type: Boolean }, connections: { type: Array } },
  computed: {
    ...mapState({
      user: state => state.user.self
    }),
    connectionsActive() {
      if (this.connections) {
        let allLength = this.user.subscription.maxNumOfActiveTasks
        let activeLength = this.connections.filter(x => x.status === 'ACTIVE')
          .length
        return `${activeLength}/${allLength}`
      } else {
        return 0 / 0
      }
    }
  }
}
</script>
